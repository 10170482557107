import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ImageComponent } from '@array-app/frontend/files';
import { AlgoliaProduct, Product } from '@array-app/shared/types';

@Component({
	standalone: true,
	selector: 'app-product-card',
	imports: [ImageComponent, CommonModule, MatTooltipModule],
	templateUrl: 'product-card.component.html',
	styleUrls: ['product-card.component.scss']
})
export class ProductCardComponent {
	@HostBinding('class')
	@Input()
	size: 'small' | 'medium' | 'large' = 'large';

	@Input() product!: Product | AlgoliaProduct;

	@HostBinding('class.no-shadow')
	@Input()
	noShadow = false;
}
