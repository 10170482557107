import { CommonModule } from '@angular/common';
import {
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output
} from '@angular/core';
import {
	TextBadgeComponent,
	TitleDescriptionComponent
} from '@array-app/frontend/common';
import { ResourceListItemComponent } from '@array-app/frontend/resource-list';
import { Product } from '@array-app/shared/types';
import { TranslateModule } from '@ngx-translate/core';
import { ProductCardComponent } from '../product-card/product-card.component';

@Component({
	standalone: true,
	imports: [
		TranslateModule,
		ResourceListItemComponent,
		CommonModule,
		TitleDescriptionComponent,
		TextBadgeComponent,
		ProductCardComponent
	],
	selector: 'app-resource-list-product-item',
	templateUrl: 'resource-list-product-item.component.html',
	styleUrls: ['resource-list-product-item.component.scss']
})
export class ResourceListProductItemComponent {
	@HostBinding('class.resource-item-alias')
	resourceItemAlias = true;

	@Input() product!: Product;

	@Input() disabled = false;

	@Input() clickable = false;

	@Output()
	valueSelect = new EventEmitter<void>();

	onSelect() {
		this.valueSelect.emit();
	}
}
