<app-resource-list-item [disabled]="disabled">
	<ng-container *ngIf="clickable">
		<button type="button" [disabled]="disabled" (click)="onSelect()">
			<ng-container *ngTemplateOutlet="content"></ng-container>
		</button>
	</ng-container>
	<ng-container *ngIf="!clickable">
		<div>
			<ng-container *ngTemplateOutlet="content"></ng-container>
		</div>
	</ng-container>
</app-resource-list-item>

<ng-template #content>
	<div start>
		<app-product-card
			[noShadow]="true"
			size="small"
			[product]="product"
		></app-product-card>
	</div>
	<app-title-description
		[title]="product.name"
		[description]="product.description"
	></app-title-description>

	<div end>
		<app-text-badge
			*ngIf="product.deletedAt"
			type="danger"
			class="margin-right-2"
		>
			{{ 'generic.deleted' | translate }}
		</app-text-badge>

		<ng-content *ngIf="!product.deletedAt"></ng-content>

		<ng-content select="[static]"></ng-content>
	</div>
</ng-template>
