<app-card-header [collapsible]="false">
	{{ 'products.select-products' | translate }}
</app-card-header>

<div class="search">
	<app-search-wrapper
		[canClear]="!!search"
		(clear)="onClear()"
		(click)="$event.stopPropagation()"
	>
		<input
			autofocus
			(click)="$event.stopPropagation()"
			[placeholder]="'products.search-products' | translate"
			type="text"
			[value]="search"
			#searchInput
		/>
	</app-search-wrapper>
</div>

<div class="content">
	<app-resource-list-table [loading]="loading">
		<app-resource-list-product-item
			*ngFor="let item of content; trackBy: trackByFn"
			[product]="item"
			[disabled]="selected.has(item.id)"
			[clickable]="true"
			(valueSelect)="onSelect(item)"
		></app-resource-list-product-item>
	</app-resource-list-table>

	<app-resource-list-empty-state
		size="small"
		*ngIf="!loading && content && content.length === 0"
		[searching]="!!search"
		icon="fa-solid fa-cube"
		header="tables.products.empty.header"
		[canClear]="false"
	></app-resource-list-empty-state>
</div>

<div class="footer">
	<p class="text-tertiary small margin-0 text-right">
		<ng-container *ngIf="loading">
			{{ 'generic.loading' | translate }}
		</ng-container>
		<ng-container *ngIf="!loading">
			{{ 'tables.show-#-result' | translate: { count: content.length } }}
		</ng-container>
	</p>
</div>
