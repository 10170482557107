import { Injectable, Injector } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { AuthService } from '@array-app/frontend/authentication';
import { BaseApiService } from '@array-app/frontend/common';
import { Product } from '@array-app/shared/types';

@Injectable({
	providedIn: 'root'
})
export class ProductAdminApiService extends BaseApiService<Product> {
	constructor(
		protected override readonly firestore: Firestore,
		protected override readonly authService: AuthService,
		protected override readonly injector: Injector
	) {
		super(firestore, authService, injector, 'products');
	}
}
