import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Product } from '@array-app/shared/types';
import { ProductAdminApiService } from '../services';

@Injectable({
	providedIn: 'root'
})
export class ProductDetailResolve {
	constructor(private readonly productAdminService: ProductAdminApiService) {}

	async resolve(route: ActivatedRouteSnapshot) {
		const id = route.params['id'];
		return this.productAdminService
			.findById(id)
			.then((d) => d.data() as Product);
	}
}
