<app-image
	[size]="size"
	[src]="product.thumbnailURL"
	[matTooltip]="size === 'small' ? product.name : ''"
	emptyIcon="fa-solid fa-cube"
></app-image>

<div class="info">
	<h4 class="line-clamp-2" [title]="product.name">{{ product.name }}</h4>
	<p *ngIf="product.caption" class="line-clamp-1" [title]="product.caption">
		{{ product.caption }}
	</p>
</div>
