import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { getDocs } from '@angular/fire/firestore';
import { RouterModule } from '@angular/router';
import {
	DestroyService,
	SearchWrapperComponent,
	SelectBaseMenuComponent,
	ValueSelect
} from '@array-app/frontend/common';
import {
	ResourceListEmptyStateComponent,
	ResourceListTableComponent
} from '@array-app/frontend/resource-list';
import { CardHeaderComponent } from '@array-app/frontend/ui/component';
import { Product } from '@array-app/shared/types';
import { productSearch } from '@array-app/shared/utils';
import { TranslateModule } from '@ngx-translate/core';
import { ProductAdminApiService } from '../../services';
import { ResourceListProductItemComponent } from '../resource-list-product-item/resource-list-product-item.component';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		SearchWrapperComponent,
		CardHeaderComponent,
		ResourceListEmptyStateComponent,
		ResourceListTableComponent,
		ResourceListProductItemComponent,
		RouterModule
	],
	selector: 'app-select-product-menu',
	templateUrl: 'select-product-menu.component.html',
	styleUrls: ['select-product-menu.component.scss']
})
export class SelectProductMenuComponent extends SelectBaseMenuComponent<Product> {
	constructor(
		protected override readonly destroy$: DestroyService,
		private readonly productAdminService: ProductAdminApiService
	) {
		super(destroy$);
	}

	/**
	 * Fetches all of the users once and stores them for quick indexing.
	 * Todo: update logic to fetch users every time search changes. Maybe switch
	 * to Algolia.
	 */
	override async fetchContent() {
		this.loading = true;
		getDocs(this.productAdminService.collectionQuery).then((docs) => {
			docs.forEach((doc) =>
				this.allContent.push({
					...doc.data(),
					value: doc.ref
				})
			);
			this.initSearch();
			this.loading = false;
		});
	}

	/**
	 * "initializes" the search based on the new search term.
	 */
	override initSearch(search = this.search) {
		this.content = productSearch(
			this.allContent,
			search
		) as ValueSelect<Product>[];
	}
}
