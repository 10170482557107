import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { PageTitleResolve } from '@array-app/frontend/common';
import { Product } from '@array-app/shared/types';
import { TranslateService } from '@ngx-translate/core';
import { ProductAdminApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class ProductPageTitleResolve extends PageTitleResolve {
	constructor(
		protected override readonly translate: TranslateService,
		private readonly productAdminService: ProductAdminApiService,
		private readonly router: Router
	) {
		super(translate);
	}

	override async resolve(route: ActivatedRouteSnapshot) {
		const productId = route.params['id'];
		const product = await this.productAdminService
			.findById(productId)
			.then((res) => res.data() as Product);

		if (!product) {
			this.router.navigate(['app/products']);
			return '';
		}

		document.title =
			this.pageTitleBase +
			this.translate.instant('route.title.products') +
			' | ' +
			product.name;
		return document.title;
	}
}
