import { ModelConfigs } from '@array-app/shared/types';

export const ProductModelConfig: ModelConfigs = {
	universal: {
		type: 'universal',
		label: 'models.types.universal.label',
		description: 'models.types.universal.description',
		accepts: ['.gltf', '.babylon', '.obj']
	},
	ios: {
		type: 'ios',
		label: 'models.types.ios.label',
		description: 'models.types.ios.description',
		accepts: ['.unity3d']
	},
	android: {
		type: 'android',
		label: 'models.types.android.label',
		description: 'models.types.android.description',
		accepts: ['.unity3d']
	}
};
